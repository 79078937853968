<template>
  <v-container fill-height>
    <v-layout justify-center>
      <v-flex xs12 sm12 md11 lg10 xl8>
        <v-form class="elevation-5">
          <template>
            <v-toolbar color="primary" dark dense>
              <v-icon class="mr-2">mdi-view-module-outline</v-icon>
              <v-toolbar-title>{{ tituloForm }}</v-toolbar-title>
              <v-icon class="ml-2" small>mdi-asterisk</v-icon>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metClear"
                  >
                    mdi-refresh
                  </v-icon>
                </template>
                <span>Refresh</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metVoltar()"
                  >
                    mdi-arrow-left-circle-outline
                  </v-icon>
                </template>
                <span>Voltar</span>
              </v-tooltip>
            </v-toolbar>
          </template>
          <template>
            <v-container fluid>
              <v-row dense>
                <v-col cols="auto" md="2" align-self="center">
                  <v-text-field
                    hide-details="auto"
                    v-model="moduloLocal.id"
                    label="ID"
                    readonly
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="10" align-self="center">
                  <v-text-field
                    hide-details="auto"
                    v-model="moduloLocal.modulo"
                    :error-messages="compModuloErrors"
                    label="Modulo"
                    required
                    outlined
                    @input="$v.moduloLocal.modulo.$touch()"
                    @blur="$v.moduloLocal.modulo.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="ml-4 mr-4 mb-4"
                  color="green darken-2"
                  v-bind="attrs"
                  v-on="on"
                  @click="metSubmit"
                >
                  mdi-check-circle
                </v-icon>
              </template>
              <span>Salvar</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="mr-4 mb-4"
                  color="red"
                  v-bind="attrs"
                  v-on="on"
                  @click="metVoltar()"
                >
                  mdi-backspace
                </v-icon>
              </template>
              <span>Cancelar</span>
            </v-tooltip>
          </template>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

const { mapState, mapActions } = createNamespacedHelpers("adminModulos");

export default {
  mixins: [validationMixin],
  validations: {
    moduloLocal: {
      modulo: { required },
      campo: { required },
    },
  },

  data: () => ({
    tituloForm: "",
    moduloLocal: {},
  }),
  computed: {
    ...mapState(["moduloSelecionado"]),

    compModuloErrors() {
      const errors = [];
      if (!this.$v.moduloLocal.modulo.$dirty) return errors;
      !this.$v.moduloLocal.modulo.required && errors.push("Nome requerida.");
      return errors;
    },
    compCampoErrors() {
      const errors = [];
      if (!this.$v.moduloLocal.campo.$dirty) return errors;
      !this.$v.moduloLocal.campo.required && errors.push("Descricão requerida");
      return errors;
    },
  },
  watch: {
    moduloSelecionado(moduloNovo) {
      this.metSincronizar(moduloNovo);
    },
  },
  created() {
    this.metSincronizar(this.moduloSelecionado);
    this.moduloSelecionado
      ? (this.tituloForm = "Editar Módulo")
      : (this.tituloForm = "Novo Módulo");
  },
  methods: {
    ...mapActions(["actCriarModulo", "actEditarModulo"]),

    async metSalvarModulo() {
      this.moduloLocal.modulo = this.moduloLocal.modulo.trim();

      switch (this.moduloSelecionado) {
        case undefined:
          this.actCriarModulo({ modulo: this.moduloLocal });
          this.$root.snackbar.show({
            type: "success",
            message: "Módulo Criado!",
          });
          break;
        default:
          await this.actEditarModulo({ modulo: this.moduloLocal });
          this.$root.snackbar.show({
            type: "success",
            message: "Módulo Salvo!",
          });
      }
    },

    metSincronizar(novoModulo) {
      this.moduloLocal = Object.assign({}, novoModulo || { id: null });
    },
    metSubmit() {
      this.$v.$touch();
      this.metSalvarModulo();
    },
    metClear() {
      this.moduloLocal.modulo = this.staPerfilSelecionado.modulo;
      this.moduloLocal.campo = this.staPerfilSelecionado.campo;
    },
    metVoltar() {
      this.$router.back();
    },
  },
};
</script>
